import { ENVIRONMENT } from '../model/config/config';

export const logger = {
  debug: (...args: any[]) => {
    if (ENVIRONMENT === 'development' || ENVIRONMENT === 'local') {
      console.log(...args);
    }
  },
  error: (...args: any[]) => {
    if (ENVIRONMENT === 'development' || ENVIRONMENT === 'local') {
      console.error(...args);
    }
  }
};
